.slideshow-element{
    width: 100%;
    display: block;
    position: relative;
}
.slideshow-element>img{
    width: 100%;
}

.slideshow-element>div{
    font-size: 40px;
    font-weight: 700;
    color: white;
    position: absolute;
    text-shadow: 0 0 10px black;
    top: 30%;
    width: 100%;
    text-align: center;
    transform: translate(0,-30%);
    display: inline-block;
    z-index: 3;
}

@media screen and (max-device-width:500px) {
    
    .slideshow-element>div{
        font-size: 20px;
    }
    .slideshow-element>img{
        height: auto;
        width: 150%;
    }
}

