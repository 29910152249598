@import 'config.scss';

.header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 10;    
    min-width: 800px;
}

.top-header{
    border: 1px solid $maincolor;
    border-bottom: none;
    width: 100%;
    height: 100px;
    position: relative;
}

.top-header>#logo{
    top:50%;
    transform: translate(0,-50%);
    font-size: 18px;
    left: 200px;
    font-weight: 700;
}

.top-header #slogan{
    position: relative;
    text-align: center;
    margin-left: -3px;
    font-family: serif;
    opacity: 0.8;
    font-size: 16px;
}

.top-header>#logo>img{
    height: 35px;
    width: 300px;   
}
.top-header>#imglogo{
    left: 70px;
    top: 50%;
    transform: translate(0,-50%);
}
.top-header>#imglogo>img{
    width: 92px;
}

.top-header>div{
    display: inline-block;
    position: absolute;
}

.top-header #hd-contact-info{
    top:50%;
    transform: translate(0,-50%);
    font-size: 18px;
    font-weight: 500;
    right: 25%;
}

#hd-contact-info>div>div{
    display: inline-block;
    vertical-align: middle;
}

.menu-header{
    position: relative;
    width: 100%;
    padding-left: 5%;
    background-color: #1d67b9;
    font-size: 20px;
    font-weight: 500;
    color: white;        
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.menu-header>div{
    display: inline-block;
}
.menu-header #menu-item{
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.menu-header #menu-item:hover{
    color: $maincolor;
    background-color: white;
}

@keyframes showanimation {
    from {opacity: 0;}
    to {opacity: 1;}
  }

.menu-header .submenu {
    position: absolute;
    display: block;
    width: 1226px;
    height: auto;
    background-color: #1d67b9;
    padding-bottom: 20px;
    border-top: 1px solid;    
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 5px 5px 5px rgba(122, 122, 122, 0.404);
    transition: all 0.5s;
    animation-name: showanimation;
    animation-duration: 0.5s;
}



.menu-header .submenu.showsubmenu{
    height: auto;
}

.menu-header>.submenu > div{
    display: inline-block;
    width: 48%;
    vertical-align: top;
    padding: 1%;    
    font-weight: 400;    
    transition: all 0.5s;
    animation-name: showanimation;
    animation-duration: 0.7s;
}

.submenu #menudescription>div{

}

#menudescription #description{
    font-size: 17px;    
    font-weight: 400;
    width: 96%;
    padding-bottom: 10px;
}

.linkitem{
    cursor: pointer;
    text-decoration: underline;    
    font-weight: 400;    
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
}

.menu-header .submenu #submenuitems{
}

.menu-header .submenu #submenuitems > div{
    padding-bottom: 5px;
    cursor: pointer;
    text-align: center;
    transition: 0.2s all;
}

.menu-header .submenu #submenuitems > div:hover{
    color: rgb(120, 224, 255);
}

.menu-centered-description{
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.menu-header > .submenu > .menu-centered-description{
    width: 60%;
}
.social-links{
    text-align: center;
}

.social-links div{    
    display: inline-block;
    padding-right: 12px;
}
.social-links img{
    height: 35px;
    cursor: pointer;
}

#social-links .social-links img{
    height: 30px;
}

#social-links{
    top: 50%;
    right: 50px;
    transform: translate(0,-50%);
}
#social-links #contactus{
    margin-bottom: 8px;
    margin-right: 10px;
    font-weight: 500;
    color: #4c00ff;
}

#social-links>div>div{
    display: inline-block;
    vertical-align: middle;
}

.icon-size-12{
}

#slideshowheader{
    width: 97%;
    border-radius: 8px;
    height: 700px;
    border: 1px solid $maincolor;
    overflow: hidden;
    margin-bottom: 50px;
}

@media screen and (max-device-width:800px){
    .top-header #slogan{
        font-size: 12px;
        font-weight: 100;
        padding-left: 0px;
        margin: 0px;
    }
    .social-links div{    
        display: inline-block;
        padding-right: 8px;
        padding-left: 8px;
    }

    #slideshowheader{
        height: 300px;

    }

    .top-header>#logo>img{
        height: 20px;
        width: 180px;
    }
    .social-links img{
        height: 35px;
        cursor: pointer;
    }
    #burger>img{
        width: 90px;        
    }
    #burger{
        position: absolute;
        right: 10px;
        top:51%;
        transform: translate(0,-50%);
    }
    .menu-header{
        padding-top: 5px;
        font-size: 18px;
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
    .menu-header #menu-item{
        margin: 2px;
        padding: 3px;
        border-radius: 4px;
        background-color: #4379b5;
    }
    .submenu{
        margin-top: 10px;
        left: 50%;
        transform: translate(-50%,0);
    }
    .submenu #submenuitems {        
        text-decoration: underline;
    }
    .menu-header>.submenu > div{
        display: block;
        width: auto;
    }
    .menu-header>.submenu {
        width: 90%;
        padding-top: 10px;
        font-size: 18px;
    }
    .linkitem{
        font-size: 17px;
        padding-bottom: 10px;
    }
    #menudescription {
       padding-left: 20px;
       padding-right: 20px;
       width: auto;
    }
    #menudescription #description{
        font-size: 14px;
    }
    .top-header > #imglogo{
        left: 5px;
    }
    .top-header > #logo{
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .top-header{
        border: none;
        height: 120px;
        border-bottom: 1px solid #006CE4;
    }
    .header{
        min-width: auto;
    }
}

@media screen and (max-device-width:500px) {

}