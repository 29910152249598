@import 'config.scss';

.admin-page{
    display: flex;
    justify-content: center;
    background-color: gainsboro;
}

.admin-page>div{    
    padding-top:100px;
    background-color: white;
    padding-bottom: 700px;
    padding-left: 50px;
    padding-right: 50px;
}

.close-icon{
    background-color: gainsboro;
    padding: 5px;
    padding-top: 3px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.inline-blocks>div{
    display: inline-block;
}

.inline-blocks>.add-button{
    margin-left: 10px;
}
.inline-blocks .options-blocks{
    margin-left: 10px;
}
.close-icon-main{
    background-color: rgb(255, 255, 255);
    padding: 5px;
    padding-top: 3px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.modal-window{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(150, 150, 150, 0.5);
    width: 100%;
    height: 100%;
}

.modal-window>div{
    background: white;
    width: 1000px;
    height: 600px;
    position: absolute;
    padding: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    overflow: auto;
}

.modal-window .close-icon{
    position: absolute;
    right: 40px;
}

.modal-window-small .close-icon-main{
    position: absolute;
    right: 0px;
    top: 0px;
}

.input-block>label{
    margin-right: 5px ;
}

#add_apartment_block> div{
    margin: 5px;
}

#add_apartment_block .properties-block{
    margin-top: 20px;
}

.options-blocks{
    margin-top: 10px;
}

.options-blocks>div{
    background-color: gainsboro;
    display: inline-block;
    padding: 10px;
    margin-right: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.properties-block .blue-button{
    padding: 10px;
    font-size: 16px;
    height: 40px;
    width: 150px;
    margin-top: 20px;
}

.properties-block #option{
    border: 1px gray solid;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
}

.delete-button{
    background-color: gray;
    color: white;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.delete-button:hover{
    color: black;
}

.edit-button{
    background-color: #014277;
    color: white;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.edit-button:hover{
    color: rgb(136, 136, 136);
}
.add-button{
    background-color: rgb(3, 110, 69);
    color: white;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.add-button:hover{
    color: rgb(170, 170, 170);
}

.options-blocks>div>label{
    color: black;
    background-color: white;
    margin-left: 10px;
    padding: 2px;
    border-radius: 50%;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.admin-page table,th,td{
    padding: 10px;
    text-align: center;
}

.admin-page td,th{
    border-bottom: 1px dotted gray;
}

.apartment-table div{
    margin: 5px;
    display: inline-block;
}

.buttons-block{
    margin-top: 40px;
}

.buttons-block > div{
    display: inline-block;
    margin-right: 20px;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: inline-block;
    background: #2571b0;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

#drop_zone {
    border: 1px dotted $maincolor;
    border-radius: 8px;
    width: 200px;
    height: 80px;
    padding: 10px;
    margin-top: 20px;
    display: inline-block;
    margin-right: 40px;
  }
  #apart-images>div{
    position: relative;
    display: inline-block;
    margin: 20px;    
    overflow: hidden;
  }
  #apart-images>div>img{
    width: 150px;
  }
  
  #apart-images>div>input{
    position: absolute;
    bottom: 0;
    left: 0;
  }
  

  #apart-images>div>div{
    position: absolute;
    z-index: 5;
    right: 0;
  }