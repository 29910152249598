@import 'config.scss';

#apart-card{
    color: #2b2b2b;
    font-size: 15px;
    min-height: 310px;
    max-height: 400px;
    position: relative;
    width: 270px;
}

#apart-card>div{        
    padding-left: 10px;
    padding-right: 10px;
}

#apart-card #neighborhood{
    font-size: 12px;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#apart-card #apart-image{
    width: 270px;
    height: 200px;
    padding: 0px;
    background-color: gray;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow: hidden;
}

#apart-card #apart-image .promotion-block{
    position: absolute;
    bottom: 0;
    background-color: rgb(4, 104, 4);
    color: white;
    width: 100%;
    text-align: center;
    font-size: 19px;
    padding-bottom: 3px;
    opacity: 0.9;
}

#apart-card #apart-image>img{
    max-height: 210px;
    max-width: 270px;
    position: relative;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

#apart-card #price{
    font-size: 18px;
    margin-top: 5px;
    color: $maincolor;
}

#apart-card #name{    
    font-size: 13px;
    padding-bottom: 32px;    
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

#apart-card #location{
    color: gray;
    margin-bottom: 5px;
}

#apart-card #icons{
    position: absolute;
    bottom: 10px;
    padding: 0px;
    padding-left: 5px;
}

#apart-card #icons>div{
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    height: 20px;
}

#apart-card #icons>div:first>img{
    margin-left: 0px;
}

#apart-card #icons>div>img{
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 2px;
}

#aparts-cards{
    margin-top: 20px;
}

#aparts-cards  #apart-card{
    margin: 15px;
    margin-left: 0px;
    margin-top: 0px;    
    box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 16%);
    border-radius: 8px;
    display: inline-block;
    vertical-align: top;
}

#apart-result>div:first-child{
    min-height: 340px;
    position: relative;
}

#apart-result>#pagination{
    position: relative;
}

#apart-result>#pagination>div{
    display: inline-block;
    font-size: 30px;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 5px;
    border-radius: 8px;
    cursor: pointer;
    margin: 5px;
    border: 1px solid $maincolor;
    outline: 0;
    background-color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#apart-result>#pagination>div:hover{
    background-color: $maincolor;
    color: white;
}

#apart-result > #pagination > div.selected-page{
    background-color: $maincolor;
    color: white;
}

#aparts-cards>div{
    display: inline-block;
}

#no-results-found{
    // border: $maincolor 1px solid;
    // padding: 10px;
    // border-radius: 8px;
}

#no-results-found > #not-found-message{
    font-size: 20px;
    color: rgb(46, 46, 46);
}

#no-results-found > #contact-us-message{
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: rgb(46, 46, 46);
}

#contact-card{
    padding-top: 20px;
}

#check-other{
    margin-bottom: 20px;
    width: 320px;
    font-size: 20px;
    background-color: $maincolor;
    padding: 10px;
    color: white;    
    box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.349);
}

.apart-photos{
    //box-shadow: 0px 3px 3px 0px gray;
    //padding:20px;
    //border: 3px solid $maincolor;
    border-radius: 8px;
    margin-top: 20px;
    max-height: 300px;
    max-width: 1200px;
    overflow-y: auto;
}

.apart-photos img{
    display: inline-block;
    max-height: 250px;
    margin:10px;
}

.full-apart-description>.blue-button{
    margin-top: 20px;
    height: 30px;
    padding: 10px;
}

.full-apart-description{
    font-size: 18px;
    margin-left: 10px;
    padding: 20px;
    max-width: 1150px;
    margin-bottom: 50px;
    background-color: rgba(2, 84, 167, 0.05);
}

.full-apart-description #status{
    
    margin-top: 5px;
    margin-bottom: 10px;
}
.full-apart-description #status > label{
    font-size: 24px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

.strikethrough-price{
    text-decoration: line-through rgb(131, 16, 16) 3px;
}

.promotional-price{
    color: rgb(1, 146, 1);
}

.full-apart-description #status > div{
    margin-top:10px;
    color: #707297;
}

.full-apart-description .Sold > label{
    color: white;
    background-color: rgb(143, 4, 4);
}

.full-apart-description .Reserved > label{
    color: white;
    background-color: rgb(143, 134, 4);
}
.full-apart-description .Promotion > label{
    color: white;
    background-color: rgb(10, 124, 0);
}

.full-apart-description table.mobile-table{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    background-color: aliceblue;
    margin-top: 20px;
    padding: 5px;
}

.full-apart-description table.mobile-table th{
    text-align: left;
    
}

.full-apart-description > #title{
    font-size: 26px;
    font-weight: 600;
    color:$darkcolor;
}

.full-apart-description > #features {
    padding-top: 10px;
    
}

.full-apart-description > #features  #title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
}

.full-apart-description > #features  #options>div{
    display: inline-block;
    padding: 4px;
    padding-left: 6px;
    padding-right: 6px;
    margin: 4px;    
    background-color: rgb(236, 236, 236);
}

.main-page-content> #not-found{
    padding: 20px;
    font-size: 30px;
    margin-bottom: 50px;
    margin-top: 50px;
    font-weight: 600;
}

.enlarge-block{
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(128, 128, 128, 0.459);
}

.enlarge-block>img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 90%;
    max-height: 90%;
}

.full-apart-description #location{
    font-size: 18px;
}

@media screen and (max-device-width:500px) {
    .full-apart-description>.blue-button{
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
    }
    .full-apart-description > #title{
        width: 100%;
        text-align: center;
    }

    #aparts-cards{
        padding-bottom: 30px;
    }

    .apart-photos{
        max-height: 400px;
        text-align: center;
    }
    .apart-photos img{
        display: block;
        max-height: 1000px;
        width: 90%;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0px);
        margin-left: 0px;
        margin-right: 0px;
    }
    .full-apart-description{
        margin-left: 0px;
        background-color: rgb(255 255 255 / 74%);
    }
    .full-apart-description #title{
        font-size: 20px;
    }
    #no-results-found > #not-found-message{
        font-size: 16px;
    }
    #no-results-found{
        padding: 10px;
        background-color: white;
        text-align: center;
    }
    #apart-result>div:first-child{
        min-height: 340px;
        position: relative;
    }
    #check-other{
        width: 80%;
    }
    #aparts-cards>div{
        display: block;
    }
    #apart-result>#pagination{
        text-align: center;
    }
    #apart-card #apart-image{
        width: 100%;
        height: 250px;
    }
    #apart-card #name{
        font-size: 18px;
        padding-top: 10px;
    }
    #apart-card #price{
        font-size: 20px;
    }
    #apart-card #icons{
        padding-left: 0px;
        text-align: center;
        width: 100%;
    }
    #apart-card #icons > div{
        font-size: 14px;
        height: 25px;
    }
    #apart-card #icons > div > img{
        width: 25px;
        margin-left: 6px;
        margin-right: 6px;
    }
    #apart-card #icons > div:first > img{
        margin-left: 0px;
    }
    #apart-card #apart-image > img{
        width: 100%;
        max-width: none;
        max-height: none;
    }
    #aparts-cards #apart-card:last-child{
       // margin-bottom: 100px;
    }


    #aparts-cards #apart-card{
        background-color: white;
        margin-bottom: 40px;
    }

    #apart-result #aparts-cards #apart-card{
        margin-bottom: 40px;
    }
    
}