@import 'config.scss';

#root{
    min-width: 1210px;
}
.under-maintenance{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 100px;
}

.middle-size-text{
    font-size: 14px;
    text-align: justify;
}
#page{
    background-color: #cfcfcf;
    display: flex;
}

.left-menu{
    display: flex;
    position: relative;
    justify-content: center;
    width: 350px;
    background-color: white;
    vertical-align: top; 
    flex: 1;
}
.input-block-admin>div{
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    margin-left: 10px;
}

.input-block-admin>label{    
    vertical-align: top;
}
.input-block-admin>textarea{    
    vertical-align: top;
    margin-top: 5px;
    width: 300px;
    height: 50px;
}

.input-block>div{
    padding-bottom: 5px;
}

.input-small{
    height:30px;
    width: 210px;
    border-radius: 8px;
    border: 1px solid $maincolor;
    color: $maincolor;
    font-size: 16px;
}

.input-from-to{
    width: 110px;
    margin-right: 10px;    
}

#left_menu{
    display: block;
    position: sticky;
    top: 160px;
    height: 700px;
    width: 90%;
    max-width: 350px;
    border: 1px solid $maincolor ;
    border-radius: 8px;  
    padding-top: 20px;
}

#left_menu .blue-button{
}

#left_menu>div{
    position: relative;
    left:50%;
    transform: translate(-50%,0);
    margin-bottom: 20px;
    display: inline-block;
}
#left_menu>div.from-to-input{
    margin-bottom: 0px;
}

#left_menu>div>button{
    margin-right: 20px;
}

.white-button{
    background-color: white;
    border: 1px solid $maincolor;
    padding: 10px;
    color: $maincolor;
    border-radius: 8px;
    width: 80px;
    font-size: 16px;
    cursor: pointer;
}

.white-button:hover{
    color: white;
    background-color: $maincolor;
}

.main-page-content{
    padding-top: 160px;
    max-width: 1400px;
    min-height: 700px;
    display: inline-block;
    background-color: white;
    flex: 4;
}

.main-content-block{
    width: 97%;
    height: 50vw;
    min-height: 550px;
    overflow: hidden;
    max-height: 740px;
    margin-bottom: 120px;
    position: relative;
    transform: translate(0, 0);
}

.relative-centered-element{
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.blue-rounded-border{
    border: 1px solid $maincolor;
    border-radius: 8px;
}

.padding-block-content{
    padding-left: 50px;
    padding-top: 10px;
    font-size: 40px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.808);
    font-weight: 500;
}

.padding-block-content>#values-section{
    margin-top: 20px;
}

.padding-block-content>div> #value-card{
    font-size: 24px;
    width: 80%;
    max-width: 800px;
    padding: 1.2vw;
    padding-top: 0.5vw;
    box-shadow: 0 0 5px 5px rgba(104, 104, 104, 0.384);
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.padding-block-content>div> #reasons-card{
    font-size: 16px;
    width: 80%;
    max-width: 800px;
    padding: 2vw;
    border: 1px solid $maincolor;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.padding-block-content>#title>div{
    display: inline-block;
    vertical-align: middle;
}

.padding-block-content>#view-more-button{
    text-decoration: none;
    text-shadow: none;
    padding: 10px;
    position: absolute;
    right: 40px;
    top: 20px;
}

.elipse{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $maincolor;
    margin-top: 3px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

#value-card>#value-text{
    display: inline;
    vertical-align: middle;
    font-size: 18px;
}
#reasons-card>#value-title{
    font-size: 24px;
    padding-bottom: 15px;
}
#reasons-card>#value-title>img{
    width: 25px;
    margin-right: 10px;
    margin-top: 5px;
}

#values-image{
    position: absolute;
    z-index: -1;
    width: 41%;
    min-width: 400px;
    right: 0;
}
#reasons-image{
    position: absolute;
    z-index: -1;
    height: 100%;
    min-width: 400px;
    max-width: 600px;
    right: 0;
    object-fit: cover;
    object-position: -370px 0;
    filter: brightness(0.7);
}
.white-title-text{
    color: white;
    text-shadow: 0 0 10px black;
    font-size: 36px;
    font-weight: 500;
}
.blue-title-text{
    color: $maincolor;
    font-size: 36px;
    font-weight: 500;
}

.white-text{
    color: white;
    text-shadow: 0 0 10px black;
    font-size: 24px;
    font-weight: 300;
}

.blue-button{
    padding-top: 25px;
    padding-bottom: 30px;
    background-color: $maincolor;
    border-radius: 8px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    text-align: center;
    width: 200px;

}
.blue-button:hover{
    background-color: rgb(40, 40, 128);
}

.vertical-center-element{
    padding-left: 60px;
    padding-right: 20px;
    position: relative;
    top: 50%;
    transform: translate(0,-50%);
}

#find-now-image{
    position: absolute;
    z-index: -1;
    width: 100%;
    filter: brightness(0.5);
}
#find-now-image-bot{
    position: absolute;
    z-index: -1;
    width: 100%;
    filter: brightness(0.5);
    object-position: 0 -800px;
}

.tab-block > #tab_links>div{
    display: inline-block;    
    margin-right: 10px;
    font-weight: 400;
    font-size: 24px;
}

.tab-block > #tab_links>div:hover{
    text-decoration: underline;
    opacity: 1;
    cursor: pointer;
}

.selected-tab-text{
    text-decoration: underline;
}
.faded-tab-text{
    opacity: 0.5;
}

.tab-block>#content>#image{
    display: inline-block;    
    vertical-align: middle;
    filter: brightness(0.7);
    max-width: 25%;
}
.tab-block>#content>#text{
    display: inline-block;
    width: 60%;
    vertical-align: middle;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.829);
}

.tab-block>#content>#text>.blue-button{
    margin-top: 20px;
}

.tab-block>#content>#image>img{
    width: 25%;
    min-width: 250px;
    border-radius: 8px;
}
#subscribe_input_block{
    width: 100%;
    position: relative;
}

#subscribe_input_block>img{
    position: absolute;
    left: 15px;
    top:14px;
}

#subscribe_input_block>input{
    height:57px;
    width: 55%;
    vertical-align: top;
    margin-right: 20px;
    border-radius: 8px;
    border:none;
    padding-left: 70px;
    color: $maincolor;
    font-size: 20px;
}

#subscribe_input_block > .blue-button{
    display: inline-block;
    height: 30px;
    padding: 15px;
}

#title .blue-button{
    right: -100px;
    padding: 10px;    
    text-decoration: none;
    text-shadow: none;
    position: relative;
}

.blue-card{
    background-color: $maincolor;
    color: white;
    font-size: 20px;
    padding: 10px;
    font-weight: 500;
    box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.349);
}

.blue-card a{
    color: white;
}

.mobile-socials{
    position: fixed;
    z-index: 99;
    /* height: 50px; */
    width: 100%;
    bottom: 0;
    background: #ffffffcf;
    padding-top: 10px;
    padding-bottom: 10px;
}

.footer{
    padding: 10px;
    padding-bottom: 40px;
    padding-top: 40px;
    //height: 200px;
    background-color: #ececec;
    font-size: 30px;
    margin-top: 30px;
    position: relative;
    text-align: center;
    display: flex
;
    justify-content: space-evenly;
}

.footer #title {
    font-weight: 600;
    padding-bottom: 10px;
    padding-left: 20px;
    font-size: 20px;
    text-align: left;
}

.footer  #block{
    display: inline-flex;
    min-width: 200px;
    max-width: 300px;
    vertical-align: top;
    text-align: center;
    position: relative;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
}

.footer  #contacts{
    padding-left: 20px;
    text-align: justify;
}

.footer  #contacts>div {
    display: block;
    padding-left: 0px;
    font-size: 16px;
}

#social-footer-icons {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    //transform: translate(0,-50%);
}

.contacts-modal{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #808080d9;
    top: 0;
    left: 0;
}

.contact-card-wrapper{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: white;
    padding: 30px;
}

.close-icon-wrapper{
    position: fixed;
    top: -16px;
    right: 0;
    margin-right: -12px;
}

#value-text > div{
    font-size: 14px;
    white-space: pre-line;
}

#our-services {
    margin-top: -60px;
    margin-bottom: 50px;
    text-align: center;
    white-space: pre-wrap;
}
#our-services div#image{
    width: 300px;
    overflow: hidden;
}

#our-services>div{
    width: 300px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 100px;
}
#our-services>div>div{
    padding-bottom: 20px;
    text-align: left;
}
#our-services #title{
    font-weight: 500;
    font-size: 20px;
}
.clickable{
    cursor: pointer;
}

.clickable:hover{
    color:#1b1385;
}

#our-services img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}

#our-services #text{
    text-align: justify;
}

.modal-window-small{
    top:0;
    position: fixed;
    background-color: rgba(128, 128, 128, 0.815);
    width: 100%;
    height: 100%;
    z-index: 12;
}
.modal-window-small input{
    display: block;
    margin-bottom: 10px;
    width: 240px;
    border-color: $maincolor;
    border-radius: 8px;
    font-size: 20px;
    color: $maincolor;
    padding: 3px;
}
.modal-window-small textarea{
    display: block;
    margin-bottom: 10px;
    width: 240px;
    border-color: $maincolor;
    border-radius: 8px;
    font-size: 14px;
    color: $maincolor;
    height: 100px;
    padding: 3px;
    resize: none;
}

.loader-box{
    left: 50%;
    top: 50%;
    position: relative;
    width: 70px;
    height: 80px;
    transform: translate(-50%, -50%);
}
.loader-box>div{
}

.modal-window-small label{
    display: block;
    padding-bottom: 5px;
    width: 250px;
}
.modal-window-small #input-block-modal{
    position: relative;
    left: 50%;
    width: auto;
    transform: translate(-50%,0);
    width: 250px;
    padding-bottom: 10px;
}
.modal-window-small>div{
    background: white;
    width: 400px;
    position: absolute;    
    padding: 0px;    
    padding-bottom: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.modal-window-small #modal-header{
    background-color: $maincolor;
    text-align: center;
    color: white;
    font-size: 30px;
    margin-bottom: 30px;
    padding: 10px;
    padding-top: 30px;
}

.modal-window-small .blue-button{
    padding: 10px;
    height: 30px;
    position: relative;
    left: 50%;
    width: 250px;
    transform: translate(-50%,0);
    margin-top: 10px;
    margin-bottom: 0;
}

.error-label{
    font-size: 12px;
    color: red;
    text-align: center;
}

li{
    text-decoration: none;
    list-style-type: none;
    padding: 2px;
}

.hardlined{
    // border: 1px solid $maincolor;
    // padding: 5px;
    // border-radius: 8px;
    // margin-bottom: 10px;
    // margin-top: -10px;
}
.bolded{
    font-weight: 600;
    text-decoration: underline;
}

.footer .link-item{
    padding-bottom: 5px;
    cursor: pointer;
}

.footer .link-item:hover{
    color: rgb(46, 25, 238);
}

#left_menu > .auocomplete-div{
    border: 1px solid $maincolor;
    width: 210px;
    height: 200px;
    position: absolute;
    z-index: 99;
    background-color: white;
    margin-top: 60px;
    border-top: none;
    overflow-y: scroll;
}

.auocomplete-div li:hover{
    color: white;
    background-color: $maincolor;
}

@media screen and (max-width:1600px) {
    #our-services>div{
        margin-right: 50px;
    }
}
@media screen and (max-width:1500px) {
    #reasons-image{
        object-position: -270px 0;
    }
    .main-content-block{
        width: 96%;
        margin-bottom: 20px;
        max-height: none;
        height: auto;
        background-color: white;
    }    
    #our-services {
        margin-top: 0px;
    }
    .vertical-center-element{
        position: absolute;
        padding-left: 30px;
        padding-right: 30px;
    }
    .tab-block>#content>#text{
        display: block;
        width: 90%;
    }
    .tab-block>#content>#image{
        display: none;
    }
}
@media screen and (max-width:1250px) {
    #aparts #title{
        font-size: 30px;
    }
    .padding-block-content > #view-more-button{
        top: 34px;
    }
}

@media screen and (max-device-width:500px) {
    #find-now-image-bot {
        object-position: 0 0;
        width: auto;
        height: 100%;
    }
    .modal-window-small>div{
        width: 350px;
    }    
    #reasons-image{
        object-position: 0px 0;
    }
    #our-services>div{
        margin-right: 0px;
    }
    .footer{
        display: block;
        padding-bottom: 50px;
    }
    .footer  #block{
        display: inline-block;
        width: 300px;
        padding-bottom: 20px;
    }
    .footer  #block #title{
        padding: 0;
    }
    .footer  #block #contacts{
        padding: 0;
        padding-top: 5px;
    }
    .footer > #contacts > div{
        padding-left: 10px;
    }
    .contact-card-wrapper{
        width: 340px;
        padding: 0px;
    }

    .contact-card-wrapper img{
        height: 30px;
    }

    .contact-card-wrapper .blue-card{
        font-size: 15px;
        padding: 5px;
    }

    .blue-card{
        font-size: 16px;
    }
    .left-menu{
        padding-top: 150px;
        display: block;
        width: 100%;
        flex: none;
        background-color: transparent;
    }
    #left_menu{
        position: relative;
        top: 0;        
        left: 50%;
        transform: translate(-50%,0);
        display: block;
        border: none;
        width: 96%;
        background-color: white;
    }
    .main-page-content{
        flex: none;
        display: block;
        position: relative;
        padding-top: 40px;
        padding-bottom: 10px;
        background-color: transparent;
    }
    .main-page-content>div{
        display: block;
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
    }
    #page{
        display: block;
        position: relative;
        background-color: #e8f0ff;
    }
    #page>div{
        display: block;
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
    }
    #root{
        min-width: 250px;
    }
    
    #our-services {
        margin-top: -30px;
    }
    .main-content-block{
        width: 96%;
        margin-bottom: 50px;
        max-height: none;
        height: auto;
        box-shadow: 0px 0px 10px 5px #0000002b;
        background-color: white;
    }
    .padding-block-content>#view-more-button{
        bottom: 10px;
        left: 50%;
        transform: translate(-50%,0);
        right: auto;
        top:auto;
    }
    .padding-block-content{
        padding: 0px;
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
    }
    .padding-block-content>#title{
        padding-top: 10px;
        text-align: center;
        font-size: 25px;
        background-color: rgb(255, 255, 255);
        padding-bottom: 10px;
    }
    #aparts-cards #apart-card{
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
        width: 90%;
        height: 400px;
    }
    .blue-rounded-border{
        border: none;
    }
    #values-image {
        position: absolute;
        z-index: -1;
        width: 100%;
        min-width: 0;
        filter: brightness(0.5);
    }
    .padding-block-content > div > #value-card{
        left: 50%;
        font-size: 20px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        position: relative;
        background-color: rgb(255, 255, 255);
        transform: translate(-50%,0);
    }
    .padding-block-content > div > #reasons-card{
        left: 50%;
        position: relative;
        transform: translate(-50%,0);
        background-color: rgb(255, 255, 255);
    }
    #find-now-image{
        width: auto;
        height: 100%;
    }
    .padding-block-content>#values-section{
        margin-top: 30px;
    }
    #subscribe_input_block > input{
        display: block;
        width: 220px;
        text-align: center;
        margin-bottom: 40px;
    }
    .vertical-center-element{
        padding-left: 30px;
        padding-right: 30px;
    }
    .subscribe_input_block{
        text-align: center;
    }
    #subscribe_input_block > .blue-button{

    }
    #aboutus{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .padding-block-content > div > #reasons-card{
        border: none;
    }
}